import {mapGetters, mapMutations} from "vuex";

export default {
  computed: {
    ...mapGetters({
      settings: 'settings/settings/settings',
      captchaToken: 'captcha/captcha/captchaToken',
      widgetId: 'captcha/captcha/widgetId',
    }),
  },
  methods: {
    ...mapMutations({
      setCaptchaToken: 'captcha/captcha/SET_CAPTCHA_TOKEN',
      setWidgetId: 'captcha/captcha/SET_CAPTCHA_WIDGET',
    }),
    renderCaptcha({smartCaptcha, container, callback: cb}) {
      const {smart_captcha_client_key: sitekey} = (this.settings);
      if(!sitekey) return;
      const widgetId = smartCaptcha.render(container, {
        sitekey,
        // hideShield: true,
        // invisible: true,
        callback: (token) => {
          this.setCaptchaToken(token);
          cb?.();
        }
      });
      this.setWidgetId(widgetId);
    },
    executeCaptcha(){
      window.smartCaptcha.execute(this.widgetId);
    },
  },
  beforeDestroy() {
    try {
      if(this.widgetId) {
        window.smartCaptcha.destroy(this.widgetId);
      }
      this.setWidgetId(null);
      this.setCaptchaToken(null);
    } catch (e) {
      console.error(e)
    }
  },
}
